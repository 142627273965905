import React from 'react';

import Layout from '../components/Layout';
import SEO from "../components/Seo"
import pic2 from '../assets/images/04.jpg';

const IndexPage = () => (
  <Layout>
  <SEO title="To the Depths" description="Forced to flee in the dead of night, a prince takes a leap of fate." />
    <div id="main">
    <div className="inner">
    <header>
    <div className="resumeTitle">
  <h1>Digital Paranoia — Prologue: To the Depths snippet</h1><h4>723 words | Fantasy / Sci-Fi</h4>
  </div>
    </header>
      <section>
        <div className="box alt">
						<div className="row gtr-uniform">
							<div className="col-12"><span className="image fit"><img src={pic2} alt="" /></span></div>
						</div>
          </div>
        <div className="storyText">
          <p>Billowing smoke had already left his lungs swollen, but Daoim Ti-kong’s options were few. Each step he sprinted forward, more pieces of the palace’s glowing skyline faded. The winding mountain slopes may have given him a lay of the land prior, but once flames overtook the grain and paddy fields, all of the land's wooded beauty became a distant memory.</p>
          <p>Charred planks — chipped and broken — were all that remained of the caravan. Wisdom told the young Prince that it was better to flee than to pray for a miracle — the one that'd bring his retainer and handmaiden back to the land of the living. He fell and wept. It wasn't shame that forced him to grind his teeth together. Duty was, in the very end, what led to his dearest and closest friends' deaths. But it was the beasts he locked eyes with that kept him from fleeing.</p>
          <p>He and his trusted few had run to the highest peak from which the only way down was to tumble back towards the flames or into the abyss. As the Prince backed up towards the rocks, he felt the gentle calling of the darkness below. It shouted for him to join — to flee to a world beyond where the loss of his kingdom and all he loved would become a distant daydream. Instead of answering, he stood.</p>
          <p>To fall any deeper would be to reach the point of no return. Remaining still would let the beasts devour him. Three stood on either side of him with backs arched and thorny manes fluffed out to shield the moons’ light. If their snarling fangs and noxious breath didn’t already perturb the once-spoiled Daoim Ti-kong, the falling rocks behind him gave the final indication he needed that all hope was lost. </p>
          <p>Moist air rose from the abyss. Even with the full summer moon rising, what lurked in the depths below remained a mystery. What stood before the Prince, however, was certain. No flesh was impervious to the jaws of a Wahlhound— mangy mutts with a squared-off snout and gaping nostrils dripping vile secretions.</p>
          <p>The boy’s arms reach out for the brush. If any branches could carry him to safety, he’d enjoy another chance at life. One footstep towards the flaking bark, however, and he was pushed away from the pines and back towards the ledge. There truly was nowhere left to turn.</p>
          <p>Was it some type of cruel joke? His homeland — which had been the homeland of five grand Kings before him — was a nation known for baked treasures and treats. It was no place for war. At least, his naive nature led him to believe that the worst would never come.</p>
          <p>A Wahlhound from the front led the pack. Taking a single charred stick from the carriage, the Prince swatted and whacked each beast that came for him. The first may have whimpered and reared, but as more and more swarmed him, he fell. If a stick provided little support, screams would surely be insufficient.</p>
          <p>That wasn't his last line of defense, however. His frail frame lacked the strength of the warriors who remained in the castle walls battling to the very end. But as he fought to free his wrapped arms from the fiend's jaws, he also fought for a moment of clarity. His mind, single the battle begun, had remained hazy.</p>
          <p>There had to be someone left. Of all the loyalists hooked into the MainBrain, one had to know a solution to his woes. No matter how burned or frightened, those sworn to serve him had to have it in them to fight to the very end. Daoim Ti-kong closed his eyes. When he opened them again, it was as if he was seeing the world through the mind of another.</p>
          <p>Their thoughts, no matter how horrified, were filled with fight. Each swing the Prince took may not have been his own — it certainly didn't feel like his own — but it protected him all the same. Kicking the fanged fiends in the gut and rolling to his feet, he strayed once again from death. The thoughts protecting him, however, were fading. Perhaps the flames had already reached the MainBrain. Or more likely, the rioters who raised his palace had come to give their former brethren a sweet release. It mattered little. Their thoughts were gone.</p>
        </div>
      </section>
    </div>
    </div>
  </Layout>
);

export default IndexPage;
